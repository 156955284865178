<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="info-box">
                <div class="info-left">
                    <div class="info-item">
                        <div class="info-title"><span>寄件人信息</span></div>
                        <div class="info-detail">
                            <div class="line">
                                <div class="line-item">
                                    <div class="line-text">姓名</div>
                                    <div class="line-detail">{{info.getMailArea.send_username}}</div>
                                </div>
                                <div class="line-item">
                                    <div class="line-text">电话</div>
                                    <div class="line-detail">{{info.getMailArea.send_mobile}}</div>
                                </div>
                            </div>
                            <div class="line-item">
                                <div class="line-text">地区</div>
                                <div class="line-detail">{{info.getMailArea.send_province}}{{'/' + info.getMailArea.send_city}}{{info.getMailArea.send_district ? '/' + info.getMailArea.send_district : ''}}</div>
                            </div>
                            <div class="line-item">
                                <div class="line-text">详细地址</div>
                                <div class="line-detail">{{info.getMailArea.send_detail_area}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="info-title"><span>收件人信息</span></div>
                        <div class="info-detail">
                            <div class="line">
                                <div class="line-item">
                                    <div class="line-text">姓名</div>
                                    <div class="line-detail">{{info.getMailArea.accept_username}}</div>
                                </div>
                                <div class="line-item">
                                    <div class="line-text">电话</div>
                                    <div class="line-detail">{{info.getMailArea.accept_mobile}}</div>
                                </div>
                            </div>
                            <div class="line-item">
                                <div class="line-text">地区</div>
                                <div class="line-detail">{{info.getMailArea.accept_province}}{{'/' + info.getMailArea.accept_city}}{{info.getMailArea.accept_district ? '/' + info.getMailArea.accept_district : ''}}</div>
                            </div>
                            <div class="line-item">
                                <div class="line-text">详细地址</div>
                                <div class="line-detail">{{info.getMailArea.accept_detail_area}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="info-title"><span>包裹信息</span></div>
                        <div class="info-detail">
                            <div class="line">
                                <div class="line-item">
                                    <div class="line-text">物品类型</div>
                                    <div class="line-detail">{{info.category_name}}</div>
                                </div>
                                <div class="line-item">
                                    <div class="line-text">物品重量</div>
                                    <div class="line-detail">{{info.weight}}</div>
                                    <div class="line-unit">KG</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-right">
                    <div class="info-item">
                        <div class="info-title"><span>物流信息</span></div>
                        <div class="info-detail">
                            <div class="line-item">
                                <div class="line-text">快递公司</div>
                                <div class="line-detail">{{info.mail_name}}</div>
                            </div>
                            <div class="line-item">
                                <div class="line-text">快递单号</div>
                                <div class="line-detail">{{info.mail_no}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="info-title"><span>包裹信息</span></div>
                        <div class="info-detail">
                            <div class="no-data" v-if="info.logistics_log.length === 0">暂无物流信息</div>
                            <el-scrollbar class="logistics-box" v-else>
                                <div class="logistics-item" v-for="(item, index) in info.logistics_log" :key="`logistics_log_${index}`">
                                    <div class="log-line">
                                        <div class="status">{{item.status}}</div>
                                        <div class="text">{{item.time}}</div>
                                    </div>
                                    <div class="log-line">
                                        <div class="text">{{item.context}}</div>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { mail_manageInfo } from '@/config/apis.js'
    export default {
        name: "SendingSee",
        data() {
            return {
                info: {
                    logistics_log: [],
                    getMailArea: {},
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            // 获取详情
            getInfo() {
                let id = Number(this.$route.query.id)
                mail_manageInfo(id).then(res => {
                    console.log('info', res)
                    this.info = res.data
                }).catch(err => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .info-box {
        padding: 0 20px 20px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        margin-top: -20px;
        .info-left {
            width: calc(50% - 40px);
            margin-left: 40px;
        }
        .info-right {
            width: calc(50% - 40px);
            margin-left: 40px;
        }
    }
    .info-item {
        margin-top: 40px;
        .info-title {
            /*color: #409eff;*/
            line-height: 40px;
            font-size: 18px;
            text-align: center;
            font-weight: 500;
            position: relative;
            margin-bottom: 10px;
            span {
                background: #fff;
                padding: 0 20px;
                z-index: 1;
                position: relative;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 1px;
                background: #e6e6e6;
                transform: translateY(-50%);
                z-index: 0;
            }
        }
        .info-detail {
            .line {
                display: flex;
                .line-item {
                    width: 1%;
                    flex: 1;
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .line-item {
                display: flex;
                border: 1px solid #e6e6e6;
                margin-bottom: 20px;
                .line-text {
                    width: 80px;
                    padding: 10px;
                    box-sizing: border-box;
                    background: #f6f6f6;
                    word-break: break-all;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .line-detail {
                    width: 1%;
                    flex: 1;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    border-left: 1px solid #e6e6e6;
                    word-break: break-all;
                    display: flex;
                    align-items: center;
                }
                .line-unit {
                    padding: 10px 5px;
                    background: #f6f6f6;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #e6e6e6;
                }
            }
        }
    }
    .no-data {
        text-align: center;
        font-size: 16px;
    }
    .logistics-box {
        height: 400px;
        ::v-deep & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .logistics-item {
            margin-bottom: 10px;
            .log-line {
                color: #666;
                display: flex;
                align-items: flex-end;
                margin-bottom: 4px;
                .status {
                    color: #333;
                    font-size: 15px;
                    margin-right: 6px;
                    font-weight: 500;
                }
            }
        }
    }
</style>